import React, { useEffect } from 'react'

import 'react-phone-input-2/lib/style.css'
import './App.css';

import { getUtalityLookups } from "./redux/features/lookups";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AuthStack from './AuthStack'
import mixpanel from 'mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import TagManager from 'react-gtm-module'
import { Helmet, HelmetProvider } from 'react-helmet-async';


import 'react-toastify/dist/ReactToastify.css';
import { LANGUAGE_TYPE } from './Helper/constants';


if(process.env.REACT_APP_MIXPANEL){
  mixpanel.init(process.env.REACT_APP_MIXPANEL, {debug: process.env.REACT_APP_IS_LOCAL,cross_site_cookie: true});
  window.mixpanel = mixpanel
}

function App() {
  const dispatch = useDispatch()
  const {lang} = useSelector(state => state.language);

  useEffect(() => {

    // window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/en-us/login`)

    // if(process.env.REACT_APP_FACEBOOK_PIXEL_ID){
    //   ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, {}, {
    //     autoConfig: true,
    //     debug: false,
    //   });
    //   ReactPixel.pageView();
    // }


    if(process.env.REACT_APP_GTAG_G){
      // install(process.env.REACT_APP_GTAG_G,{ 'send_page_view': true });
      // gtag('config', process.env.REACT_APP_GTAG_G);
      // if(process.env.REACT_APP_GTAG_AW){
      //   gtag('config', process.env.REACT_APP_GTAG_AW);
      // }
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTAG_G
      }
      TagManager.initialize(tagManagerArgs)
    }


  }, [])

  useEffect(() => {
    if(window?.groove){
      if(lang === LANGUAGE_TYPE.ar){
        window.groove.widget.init(process.env.REACT_APP_GROOVE_AR);
      }else{
        window.groove.widget.init(process.env.REACT_APP_GROOVE);
      }
    }

  }, [lang])



  useEffect(() => {
    dispatch(getUtalityLookups())
  }, [])


  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
            <Route path="*" element={<AuthStack/>}/>
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;

